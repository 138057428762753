@import '~antd/dist/antd.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Work Sans', sans-serif;
  box-sizing: border-box;
  height: 100vh;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
}

.spinner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main-content {
  margin-top: 5px;
  height: calc(100vh - 69px);
  width: 100%;
  position: relative;
}
.responders-filter {
  width: 150px;
}

.content-container {
  height: 100%;
  background-color: #ffffff;
  width: 90%;
  margin: auto;
  position: relative;
}
.spinner {
  color: #ffffff;
  font-size: 14px;
  margin-right: 10px;
}

.btn-primary {
  width: 150px;
  background-color: #1c99c5;
  border-color: #1c99c5;

  &:active, &:hover {
    background-color: #1c99c5;
    border-color: #1c99c5;
  }
}

.btn-secondary {
  // background-color: #52aa5e;
  color: #1c99c5;
  border-color: #1c99c5;

  &:hover {
    color: #1c99c5;
    border-color: #1c99c5;
  }
}
.tag-button {
  background-color: #29BF12;
  color: #ffffff;
  border: none;

  &:hover, &:active, &:focus {
    background-color: #29BF12;
    color: #ffffff;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .content-container {
    width: 100%;
  }
  .spinner {
    width: 100%;
  }
}
