.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 65px);
  text-align: center;
  font-size: 120%;

  .link {
    color: #09adee;
    cursor: pointer;
  }
}
